<template>
  <v-container
    id="dashboard"
    v-touch="{
      left: () => swipe('Left'),
      right: () => swipe('Right'),
    }"
    fluid
    tag="section"
    style="height: calc(100% - 110px)"
  >
    <v-row v-if="!fatt_summary">
      <v-progress-linear
        :active="!fatt_summary"
        color="blue"
        :indeterminate="true"
        class="ma-0"
        height="20"
        style="top: 40px;"
      >
        Loading...
      </v-progress-linear>
    </v-row>
    <v-row v-if="fatt_summary">
      <v-col
        cols="12"
        lg="4"
      >
        <h4 class="font-weight-light mt-2 ml-2">
          Area Agenti
        </h4>
        <v-select
          v-model="anno"
          :items="anni"
          label="seleziona anno"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-card-actions>
          <v-btn
            :disabled="step === 1"
            icon
            style="background-color:#99999929"
            @click="step--"
          >
            <v-icon>mdi-record</v-icon>
          </v-btn>

          <v-btn
            :disabled="step === 2"
            icon
            style="background-color:#99999929"
            @click="step++"
          >
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </v-card-actions>
        <v-window v-model="step">
          <v-window-item
            :value="1"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <base-trg-gauget-chart
                  v-if="fatt_summary"
                  color="#cecece"
                  :datasets="gaugeData"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <base-trg-radar-chart
                  v-if="fatt_summary"
                  color="#cecece"
                  :datasets="radarData"
                />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item :value="2">
            <v-col
              cols="12"
            >
              <base-trg-bar-chart
                v-if="fatt_summary"
                mobile-breakpoint="0"
                color="#cecece"
                :datasets="barData"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <base-material-stats-card
                v-if="fatt_summary"
                color="primary"
                icon="mdi-clipboard-text"
                title="Totale Fatturato "
                :value="totFatturato[anno]+' €' "
                sub-icon="mdi-tag"
                :sub-text="'Fatturato Anno Passato: ' + totFatturato[anno - 1] +' €' "
              />
            </v-col>
            <v-col
              cols="12"
            >
              <base-material-card
                icon="mdi-poll"
                title="Riepilogo Fatturato/Budget"
                class="px-5 py-3"
              >
                <tab-fatturato
                  v-if="fatt_summary"
                  :datasets="tabData"
                  :anno="anno"
                  :percbdgmese="percBdgMese"
                  style="touch-action: none; z-index:100"
                />
              </base-material-card>
            </v-col>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import datiAgente from '../../plugins/dati_agente'
  import TabFatturato from './tables/TabFatturato.vue'

  export default {
    name: 'Dashboard',
    components: { TabFatturato },
    data () {
      return {
        step: 1,
        barLabels: [],
        anni: [],
        anno: (new Date().getFullYear()),
        fatt_summary: null,
        age_summary: null,
        rendimento: 0,
        percBdgMese: null,
      }
    },
    computed: {
      ...mapState(['userinfo']),
      annocorrente: function () {
        return new Date().getFullYear()
      },
      mesecorrente: function () {
        return (1 + new Date().getMonth())
      },
      annomese: function () {
        var mese = this.mesecorrente
        if (this.anno < this.annocorrente) {
          mese = 12
        }
        return this.anno * 100 + mese
      },
      get_userinfo: function () {
        return this.$store.state.userinfo
      },
      totFatturato: function () {
        // calcolo fatturato
        var fatUlt = 0
        if (this.fatt_summary[this.anno]) {
          Object.values(this.fatt_summary[this.anno]).forEach(item => {
            fatUlt += parseFloat(item.importo_fatt)
          })
        }

        var fatScorso = 0
        if (this.fatt_summary[this.anno]) {
          Object.values(this.fatt_summary[this.anno - 1]).forEach(item => {
            fatScorso += parseFloat(item.importo_fatt)
          })
        }
        var data = {}
        data[this.anno] = fatUlt
        data[this.anno - 1] = fatScorso
        return data
      },
      gaugeData: function () {
        if (this.fatt_summary[this.anno] === undefined) {
          console.log('====cannot draw', this.anno, this.fatt_summary)
          this.setGaugeData()
          return
        }
        const percBdgMese = this.percBdgMese
        // calcolo fatturato
        var fatturato = 0
        Object.values(this.fatt_summary[this.anno]).forEach(item => {
          fatturato += parseFloat(item.importo_fatt)
        })

        // setto i budget
        var dsBdg = this.age_summary.ds_bdg[this.anno]
        var bdgMin = 0
        var bdgL1 = 0
        var bdgL2 = 0
        if (!dsBdg) {
          dsBdg = { min: 0, budg1_lib: 0, budg2_lib: 0 }
        }

        if (this.annocorrente < this.anno) {
          bdgMin = parseFloat(dsBdg.min)
          bdgL1 = parseFloat(dsBdg.budg1_lib)
          bdgL2 = parseFloat(dsBdg.budg2_lib)
        } else {
          for (var m = 1; m <= 12; m++) {
            if (m <= this.mesecorrente) {
              bdgMin += parseFloat(dsBdg.min * percBdgMese[m]) || 0
              bdgL1 += parseFloat(dsBdg.budg1_lib * percBdgMese[m]) || 0
              bdgL2 += parseFloat(dsBdg.budg2_lib * percBdgMese[m]) || 0
            }
          }
        }

        const dataset = [{
          borderColor: '#ccc',
          value: fatturato,
          data: [bdgMin, bdgL1, bdgL2, bdgL2 * 1.4],
          backgroundColor: ['red', 'yellow', '#00fffa', 'green'],
        }]

        return dataset
      },

      radarData: function () {
        const percBdgMese = this.percBdgMese
        var fattCat1 = 0
        var fattCat2 = 0
        var fattCat3 = 0
        var fattCamp = 0

        var bdgCat1 = 0
        var bdgCat2 = 0
        var bdgCat3 = 0
        var bdgCamp = 0
        var dsCorrente = this.fatt_summary[this.anno]
        var dsBdg = this.age_summary.ds_bdg[this.anno]

        Object.values(dsCorrente).forEach(item => {
          if (parseInt(item.annomese) <= this.annomese && parseInt(item.anno) === this.anno) {
            fattCat1 += parseFloat(item.importo_fatt_categoria1) || 0
            fattCat2 += parseFloat(item.importo_fatt_categoria2) || 0
            fattCat3 += parseFloat(item.importo_fatt_categoria3) || 0
            fattCamp += parseFloat(item.importo_fatt_campionatura) || 0
            if (this.rendimento <= 1) {
              var valBdgCat1 = (dsBdg.budg1_cat * dsBdg.perc_cat_1 / 100 * percBdgMese[item.mese])
              var valBdgCat2 = (dsBdg.budg1_cat * dsBdg.perc_cat_2 / 100 * percBdgMese[item.mese])
              var valBdgCat3 = (dsBdg.budg1_cat * dsBdg.perc_cat_3 / 100 * percBdgMese[item.mese])
              var valBdgCamp = (dsBdg.budg1_cat * dsBdg.perc_camp / 100 * percBdgMese[item.mese])
            } else {
              valBdgCat1 = (dsBdg.budg2_cat * dsBdg.perc_cat_1 / 100 * percBdgMese[item.mese])
              valBdgCat2 = (dsBdg.budg2_cat * dsBdg.perc_cat_2 / 100 * percBdgMese[item.mese])
              valBdgCat3 = (dsBdg.budg2_cat * dsBdg.perc_cat_3 / 100 * percBdgMese[item.mese])
              valBdgCamp = (dsBdg.budg2_cat * dsBdg.perc_camp / 100 * percBdgMese[item.mese])
            }

            bdgCat1 += parseFloat(valBdgCat1)
            bdgCat2 += parseFloat(valBdgCat2)
            bdgCat3 += parseFloat(valBdgCat3)
            bdgCamp += parseFloat(valBdgCamp)
          }
        })

        var cat1 = parseInt(fattCat1 / bdgCat1 * 100) > 100 ? 100 : parseInt(fattCat1 / bdgCat1 * 100)
        var cat2 = parseInt(fattCat2 / bdgCat2 * 100) > 100 ? 100 : parseInt(fattCat2 / bdgCat2 * 100)
        var cat3 = parseInt(fattCat3 / bdgCat3 * 100) > 100 ? 100 : parseInt(fattCat3 / bdgCat3 * 100)
        var camp = parseInt(fattCamp / bdgCamp * 100) > 100 ? 100 : parseInt(fattCamp / bdgCamp * 100)

        const dataset = [{
          label: '',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderColor: 'blue',
          pointBackgroundColor: 'blue',
          data: [cat1, cat2, cat3, camp],
        }]
        return dataset
      },
      barData () {
        if (this.fatt_summary[this.anno - 1] === undefined) {
          console.log('====cannot draw', this.anno, this.age_summary)
          this.setGaugeData()
          return
        }
        const mesi = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
        const percBdgMese = this.percBdgMese
        var rosso = 'red'
        var giallo = '#ffc800'
        var gialloScuro = '#ffa01c'
        var azzurro = '#00fffa'
        var azzurroScuro = '#009a97'
        var verde = '#00e804'
        var verdeScuro = 'green'

        var dsPassato = this.fatt_summary[this.anno - 1]
        var dsCorrente = this.fatt_summary[this.anno]
        var valBdgMin = this.age_summary.ds_bdg[this.anno].min ?? 0
        var valBdgL1 = this.age_summary.ds_bdg[this.anno].budg1_lib ?? 0
        var valBdgL2 = this.age_summary.ds_bdg[this.anno].budg2_lib ?? 0

        var LastBdgMin = this.age_summary.ds_bdg[this.anno - 1].min ?? 0
        var LastBdgL1 = this.age_summary.ds_bdg[this.anno - 1].budg1_lib ?? 0
        var LastBdgL2 = this.age_summary.ds_bdg[this.anno - 1].budg2_lib ?? 0

        var fatturato = []
        var bdgMin = []
        var bdgL1 = []
        var bdgL2 = []
        var labels = []
        var that = this
        // label annoscorso
        mesi.forEach(function (mese) {
          labels.push((that.anno - 1) + ' ' + mese)
        })
        // label annocorrente
        mesi.forEach(function (mese) {
          labels.push(that.anno + ' ' + mese)
        })

        Object.keys(dsPassato).forEach(function (key) {
          var item = dsPassato[key]
          var idx = labels.indexOf(item.anno + ' ' + mesi[item.mese - 1])
          fatturato[idx] = (item.importo_fatt)
          bdgMin[idx] = (LastBdgMin * percBdgMese[item.mese]).toFixed(2)
          bdgL1[idx] = (LastBdgL1 * percBdgMese[item.mese]).toFixed(2)
          bdgL2[idx] = (LastBdgL2 * percBdgMese[item.mese]).toFixed(2)
        })

        Object.keys(dsCorrente).forEach(function (key) {
          var item = dsCorrente[key]
          var idx = labels.indexOf(item.anno + ' ' + mesi[item.mese - 1])
          fatturato[idx] = (item.importo_fatt)
          bdgMin[idx] = (valBdgMin * percBdgMese[item.mese]).toFixed(2)
          bdgL1[idx] = (valBdgL1 * percBdgMese[item.mese]).toFixed(2)
          bdgL2[idx] = (valBdgL2 * percBdgMese[item.mese]).toFixed(2)
        })

        const data = {
          labels: labels,
          datasets: [
            {
              label: 'Budget Minimo',
              type: 'line',
              borderColor: giallo,
              borderDash: [5, 5],
              data: bdgMin,
              fill: false,
            },
            {
              label: 'Budget Liv.1',
              type: 'line',
              borderColor: azzurro,
              data: bdgL1,
              fill: false,
            },
            {
              label: 'Budget Liv.2',
              type: 'line',
              borderColor: verde,
              data: bdgL2,
              fill: false,
            },
            {
              label: 'Fatturato',
              backgroundColor: function (context) {
                // console.log('context', context)
                var index = context.dataIndex
                var value = parseFloat(context.dataset.data[index])
                var budgl2 = parseFloat(bdgL2[index])
                var budgl1 = parseFloat(bdgL1[index])
                var budgmin = parseFloat(bdgMin[index])

                if (value < budgmin) {
                  return rosso
                }
                if (value < budgl1) {
                  return gialloScuro
                }
                if (value < budgl2) {
                  return azzurroScuro
                }
                if (value > budgl2) {
                  return verdeScuro
                }
                return '#CCC'
              },
              // backgroundColor: 'rgb(255, 99, 132)',
              borderColor: '#CCC',
              data: fatturato,
            },
          ],
        }
        console.log('bardata', data)
        return data
      },
      tabData () {
        const tabdata = {
          dsfatturato: this.fatt_summary,
          dsbudget: this.age_summary.ds_bdg,
        }
        return tabdata
      },
    },
    mounted () {
      for (var y = 2020; y <= this.annocorrente; y++) {
        this.anni.push(y)
      }
      this.setGaugeData()
    },
    methods: {
      swipe (direction) {
        if (direction === 'Left') {
          this.step++
        } else {
          this.step--
        }
      },
      setGaugeData () {
        // clear all
        this.fatt_summary = null
        this.age_summary = null
        const user = this.get_userinfo
        const codage = user.properties.agente
        const opeCodice = 1
        this.ds_load(codage, opeCodice)
      },
      ds_load (codage, opeCodice) {
        console.log('====load data ', this.anno)
        datiAgente.getBudgets(codage, this.anno, opeCodice)
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            this.age_summary = jsonData
            // patch per anni senza budget
            if (this.age_summary.ds_bdg[this.anno] === undefined) {
              this.age_summary.ds_bdg[this.anno] = {}
            }
            if (this.age_summary.ds_bdg[this.anno - 1] === undefined) {
              this.age_summary.ds_bdg[this.anno - 1] = {}
            }
            // --------------------------
            this.percBdgMese = this.age_summary.perc_bdg_mese
            console.log('age_summary ', this.age_summary)
            this.ds_fatt(codage, opeCodice)
          })
      },
      ds_fatt (codage, opeCodice) {
        datiAgente.getSummary(codage, this.anno, opeCodice)
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            this.fatt_summary = jsonData
            console.log('fatt_summary ', this.fatt_summary)
            const fatturato = this.fatt_summary[this.anno].importo_fatt
            const dsBdg = this.age_summary.ds_bdg[this.anno]

            if (fatturato > dsBdg.min && fatturato < dsBdg.budg1_lib) {
              this.rendimento = 1
            }
            if (fatturato >= dsBdg.budg1_lib && fatturato < dsBdg.budg2_lib) {
              this.rendimento = 2
            }
            if (fatturato >= dsBdg.budg2_lib) {
              this.rendimento = 9
            }
          })
      },
    },

  }
</script>
