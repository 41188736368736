<template>
  <v-data-table
    dense
    :headers="headers"
    :items="dsTable"
    :items-per-page="5"
    class="elevation-1"
    @touchstart.native.stop
    @touchmove.native.stop
    @touchend.native.stop
  />
</template>
<script>
  export default {
    name: 'TabFatturato',
    props: {
      datasets: {
        type: Object,
        default: null,
      },
      anno: {
        type: Number,
        default: null,
      },
      percbdgmese: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        headers: [
          // {
          //   text: 'Anno',
          //   align: 'start',
          //   sortable: false,
          //   value: 'anno',
          // },
          { text: 'Mese', value: 'mese' },
          { text: 'Fatturato ', value: 'fatt_corrente' },
          // { text: 'Fatturato ' + (this.anno - 1), value: 'fatt_passato' },
          { text: 'Scostamento', value: 'scostamento' },
          { text: 'Budget', value: 'bdg' },
          { text: 'Delta Bdg', value: 'deltabdg' },
          // { text: 'Budget Min.', value: 'bdgmin' },
          // { text: 'delta Bdg. Min.', value: 'deltabdgmin' },
          // { text: 'Budget L1.', value: 'bdgl1' },
          // { text: 'delta Bdg. L1.', value: 'deltabdgl1' },
          // { text: 'Budget L2.', value: 'bdgl2' },
          // { text: 'delta Bdg. L1.', value: 'deltabdgl2' },
        ],
        dsTable: [],
      }
    },
    mounted () {
      console.log('tabData', this.anno, this.datasets)
      const dsPassato = this.datasets.dsfatturato[this.anno - 1]
      const dsCorrente = this.datasets.dsfatturato[this.anno]
      const dsBdg = this.datasets.dsbudget[this.anno]
      const percBdgMese = this.percbdgmese
      // Object.keys(dsCorrente).forEach(function (key) {
      //   var item = dsCorrente[key]
      //   console.log('========', item)
      // })
      // console.log('========', dsPassato)
      var dsItems = []
      for (var m = 1; m <= 12; m++) {
        var itemTable = {}
        itemTable.anno = this.anno
        itemTable.mese = m
        itemTable.fatt_corrente = dsCorrente[m].importo_fatt ?? 0
        itemTable.fatt_passato = dsPassato[m].importo_fatt ?? 0
        itemTable.scostamento = (dsCorrente[m].importo_fatt / dsPassato[m].importo_fatt * 100 - 100).toFixed(2) + ' %'
        itemTable.bdgmin = parseFloat(dsBdg.min ?? 0 * percBdgMese[m]) || 0
        itemTable.deltabdgmin = (itemTable.fatt_corrente / itemTable.bdgmin * 100 - 100).toFixed(2)
        itemTable.bdgl1 = parseFloat(dsBdg.budg1_lib ?? 0 * percBdgMese[m]).toFixed(2) || 0
        itemTable.deltabdgl1 = (itemTable.fatt_corrente / itemTable.bdgl1 * 100 - 100).toFixed(2)
        itemTable.bdgl2 = parseFloat(dsBdg.budg2_lib ?? 0 * percBdgMese[m]).toFixed(2) || 0
        itemTable.deltabdgl2 = (itemTable.fatt_corrente / itemTable.bdgl2 * 100 - 100).toFixed(2)

        itemTable.bdg = itemTable.bdgl2
        itemTable.deltabdg = itemTable.deltabdgl2 + ' %'
        if (itemTable.deltabdgl1 < 0) {
          itemTable.bdg = itemTable.bdgl1
          itemTable.deltabdg = itemTable.deltabdgl1
        }
        if (itemTable.deltabdgmin < 0) {
          itemTable.bdg = itemTable.bdgmin
          itemTable.deltabdg = itemTable.deltabdgmin + ' %'
        }

        dsItems.push(itemTable)
        // if (m <= this.mesecorrente) {
        //   bdgMin += parseFloat(dsBdg.min * percBdgMese[m]) || 0
        //   bdgL1 += parseFloat(dsBdg.budg1_lib * percBdgMese[m]) || 0
        //   bdgL2 += parseFloat(dsBdg.budg2_lib * percBdgMese[m]) || 0
        // }
      }
      console.log('=========', dsItems)
      this.dsTable = dsItems
    },
    methods: {

    },
  }
</script>
